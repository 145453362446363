import Cookies from "js-cookie";
import Element from "element-ui";
import "./styles/element-variables.scss";
import enLang from "element-ui/lib/locale/lang/en";

Vue.use(Element, {
	size: Cookies.get("size") || "medium", // set element-ui default size
	locale: enLang
});

import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "vue2-datepicker/index.css";

new Vue({
	store,
	router,
	render: (h) => h(App),
}).$mount("#app");
